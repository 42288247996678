html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  background: #F5F6F8;
  height: 100%;
}

#root {
  height: 100%;
}

a {
  color: inherit;
}

:root {
  --black: #242424;
  --white: white;
  --gray: #888888;
  --gray-light: #ccc;
  --primary: #007bff;
  --primary-hover: #0056b3;
  --red: #eb3a3a;
  --green: #1DC37E;
  --green-light: #C8F8E4;
  --font-family: 'Noto Sans', sans-serif;
}

* {
  box-sizing: border-box;
}

input[type="text"].custom {
  caret-color: var(--white);
}

button {
  padding: 10px;
  font-size: 18px;
  background-color: var(--primary);
  color: var(--white);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: var(--primary-hover);
  transition: background 0.2s ease;
}

textarea,
input {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid var(--gray-light);
  border-radius: 4px;
  font-family: var(--font-family);
}

label {
  display: flex;
  flex-direction: column;
}

select {
  border-radius: 5px;
  padding: 5px;
  font-size: 1em;
  margin-top: 5px;
}